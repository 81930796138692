import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p>2022 SC DIGITAL</p>
    </div>
  );
};

export default Footer;
